<template>
  <div class="fluid container">
    <div class="form-group form-group-lg panel panel-default">
      <div class="panel-heading">
        <h3 class="panel-title">Pianificazione viaggio treno</h3>
        Route {{ $route.params}} ; Prop viaggioTrenoId: {{viaggioTrenoId}}
      </div>
    </div>
    Viaggio treno: <input v-model="viaggioTrenoIdData" />
    <Button label="Load"  @click="loadViaggioTreno" class="p-button-success" />

    <h2>Convogli</h2>
    <pre>{{jsonConvogli | pretty }}</pre>
    <h2>UTI</h2>
    <pre>{{jsonUti | pretty }}</pre>
  </div>
</template>

<script>
import RailTosService from "../../service/tos/RailTosService";

export default {
  name: "pianificazioneTreno1", //TODO Obsoleto ?
  props: ['viaggioTrenoId'],
  service: null,
  data() {
    return {
        viaggioTrenoIdData:null,  //TODO remove
        jsonConvogli: null,
        jsonUti: null,
    };
  },
  filters: {
    pretty: function(value) {
        return JSON.stringify(value, null, 2);
    }
  },
  watch: {
    '$route' (to, from) {
      console.log("ROUTE CHANGE ",to, from);
    },
  },
  
  created(){
      this.service = new RailTosService();
      this.viaggioTrenoIdData=1;//TODO usare route param
      this.loadViaggioTreno();
  },
  mounted(){
  },
  methods: {
    loadViaggioTreno() {
      const id=this.viaggioTrenoIdData;
      console.log("Load viaggio "+ id);
      setTimeout(() => {
        this.$api.trainData.loadViaggioTreno(id).then(response => {
          // console.log("response:", response);
          if (response.success == true) {
            this.jsonConvogli=response.convogli;
            this.jsonUti=response.uti;
          } else {
            console.log("Errors:", response.errors);
          }
        });
      }, 1000);
    },
  }
}

</script>